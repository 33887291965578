<template>
  <div class="main-container">
    <div class="pt-wrapper">
      <div class="title-wrapper">
        <h1>{{ $route.query.agencyName }}</h1>
      </div>
      <div class="hd-search">
        <table-search
          :model="searchParams"
          label-width="80px"
          :isClear="true"
          :flex="'6'"
          @search="searchFn"
          :isResetByPublic="false"
          @clearSearch="clearSearchFn"
        >
          <search-item default label="">
            <el-cascader
              style="width: 100%"
              v-model="searchParams.searchAgencyCode"
              :options="orgTreeData"
              placeholder="请选择机构"
              filterable
              clearable
              :props="{
                expandTrigger: 'hover',
                value: 'code',
                checkStrictly: true,
              }"
            ></el-cascader>
          </search-item>
          <search-item default label="">
            <el-select
              v-model="searchParams.monitorSubject"
              placeholder="监测主体"
              clearable
            >
              <el-option
                v-for="item in monitorSubjects"
                :key="item.type"
                :label="item.name"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </search-item>
          <search-item default label>
            <el-select
              v-model="searchParams.orderType"
              placeholder="订单类型"
              clearable
            >
              <el-option
                v-for="item in orderTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </search-item>
          <search-item default label="">
            <el-input
              size="small"
              placeholder="订单号"
              v-model="searchParams.orderCode"
              clearable
            />
          </search-item>
          <!-- <search-item default label="">
          <el-input
            size="small"
            placeholder="省份"
            v-model="searchParams.province"
            clearable
          />
        </search-item> -->
        </table-search>
      </div>
      <div class="bd-content">
        <!-- 操作按钮 -->
        <div class="table-opt">
          <el-button type="primary" @click="changeStateAll('add')"
            >批量操作</el-button
          >
          <el-button type="primary" @click="changeStateAll('cancel')"
            >批量取消</el-button
          >
        </div>
        <pageTable
          ref="table"
          :columns="columnList"
          :params="searchParams"
          :initFalse="true"
          :api-function="$api.sysManage.systemFindAgencyOrg"
          @selectAll="selectAll"
          @select="select"
        >
          <template slot="options" slot-scope="scope">
            <el-switch
              v-model="scope.row.checkBox"
              active-color="#13ce66"
              active-value="1"
              inactive-value="0"
              @change="changeState($event, scope.row)"
            >
            </el-switch>
          </template>
        </pageTable>
      </div>
    </div>
  </div>
</template>

<script>
import { throttle } from '@/utils/public';
import { transformTree } from '@/utils/utils.js';
// import cfg from './config.js';
export default {
  name: 'institutionalMember',
  data() {
    return {
      orderScanTypes: [
        { name: '算法', type: '1' },
        { name: '词库', type: '2' },
        { name: '人工', type: '3' },
      ], //产品名称下拉
      monitorSubjects: [
        { name: '网站', type: '0' },
        { name: '微信', type: '1' },
      ],
      searchParams: {
        searchAgencyCode: '',
        agencyCode:'',
        sitesCode: '',
        monitorSubject: '',
        orderCode: '',
        scanType: '',
        province: '',
        orderType: '', //订单类型
      },
      orgTreeData: [], //机构
      orderTypeList: [
        { label: '正式订单', value: '2000' },
        { label: '试用订单', value: '1000' },
      ],
      selectData: [],
      columnList: [
        {
          type: 'selection',
        },
        {
          label: '机构名称',
          prop: 'sitesName',
        },
        {
          label: '订单类型',
          prop: 'orderTypeDesc',
        },
        {
          label: '监测主体',
          prop: 'monitorSubjectTrans',
        },
        {
          label: '订单状态',
          prop: 'statusDesc',
        },
        {
          label: '订单号',
          prop: 'orderCode',
        },
        {
          label: '下单时间',
          prop: 'orderCreateTime',
        },
        {
          label: '操作',
          prop: 'options',
          show: 'template',
        },
      ],
    };
  },
  // activated() {
  //   this.searchFn();
  // },
  created() {
    this.searchParams.agencyCode = this.$route.query.agencyCode;
    this.searchParams.searchAgencyCode = this.$route.query.agencyCode;
    this.searchFn();
  },
  mounted() {
    this.loadOrgTree();
  },
  methods: {
    selectAll(params) {
      this.selectData = params;
    },
    select(params) {
      this.selectData = params;
    },
    clearSearchFn() {
      this.searchParams = {
        searchAgencyCode:this.$route.query.agencyCode,
        agencyCode:this.$route.query.agencyCode,
        sitesCode: '',
        monitorSubject: '',
        orderCode: '',
        scanType: '',
        province: '',
      };
      this.$nextTick(() => {
        this.searchFn();
      });
    },
    searchFn: throttle(function () {
      this.$nextTick(() => {
        if (
          Array.isArray(this.searchParams.searchAgencyCode) 
        ) {
          this.searchParams.searchAgencyCode =
            this.searchParams.searchAgencyCode[
              this.searchParams.searchAgencyCode.length - 1
            ];
        }
        if(this.searchParams.searchAgencyCode===undefined){
          this.searchParams.searchAgencyCode =''
        }
        this.$refs.table.getPageDataOfAction('edit');
      });
    }, 10),

    changeState(callback, row) {
      //取消的话判断是否能取消
      if (callback == '0') {
        // 传参处理
        var params = {
          agencyCode: this.$route.query.agencyCode,
          orderCode: row.orderCode,
        };
        this.$api.sysManage
          .systemCancelSaveAgencyOrder(params)
          .then((res) => {
            if (res.msg != 'ok') {
              this.$confirm(res.msg, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              })
                .then((res) => {
                  this.modifyRes(callback, row);
                })
                .catch((res) => {
                  this.cancelRes(callback, row, res);
                });
            } else {
              this.modifyRes(callback, row);
            }
          })
          .catch((res) => {
            this.cancelRes(callback, row, res);
          });
      } else {
        this.modifyRes(callback, row);
      }
    },
    //取消操作
    cancelRes(callback, row, res) {
      row.checkBox = callback == '0' ? '1' : '0';
    },
    //保存和取消保存
    modifyRes(callback, row) {
      let url =
        callback == '0' ? 'systemRemoveAgencyOrder' : 'systemSaveAgencyOrder';
      // 传参处理
      var params = {
        agencyCode: this.$route.query.agencyCode,
        orderCode: row.orderCode,
      };
      this.$api.sysManage[url](params)
        .then((res) => {
          if (res.code === 500) {
            this.cancelRes(callback, row, res);
          } else {
            row.checkBox = callback;
            this.$message.success(callback == '1' ? '保存成功' : '关闭成功');
          }
        })
        .catch((res) => {
          this.cancelRes(callback, row, res);
        });
    },
    //一键操作
    changeStateAll(name) {
      if (this.selectData.length === 0) {
        this.$message({
          message: '请先勾选数据',
          type: 'warning',
        });
        return;
      }
      const newArr = this.selectData.map((item) => ({
        agencyCode: this.$route.query.agencyCode,
        orderCode: item.orderCode,
      }));
      this.$msg
        .confirm(
          `${name === 'add' ? '确定要批量操作吗？' : '确定要批量取消吗？'}`
        )
        .then(() => {
          if (name === 'add') {
            this.$api.sysManage.stemSaveAgencyOrderList(newArr).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: '批量保存成功',
                  type: 'success',
                });
                this.selectData = [];
                this.searchFn();
              }
            });
          } else {
            this.$api.sysManage
              .systemRemoveAgencyOrderList(newArr)
              .then((res) => {
                if (res.code == 0) {
                  this.$message({
                    message: '批量取消成功',
                    type: 'success',
                  });
                  this.selectData = [];
                  this.searchFn();
                }
              });
          }
        });
    },
    //加载机构树
    async loadOrgTree() {
      let res = await this.$api.sysManage.treeData();
      if (res.data) {
        this.orgTreeData = transformTree(res.data, {
          pId: 'pid',
          label: 'name',
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.main-container {
  background: #fff;
  .pt-wrapper {
    padding: 15px;
    .title-wrapper {
      border-bottom: 1px solid #dfe1e6;
    }
    h1 {
      font-size: 20px;
    }
    .search-wrap {
      margin: 10px 0px;
    }
  }
}
</style>
